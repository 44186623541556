
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"ca8c1f27717ce77d454d10312ee3543368db99dc":"submitForm","f849c63b1d4c74b45c5ccacb6bae9c8543a2c5b9":"getForm"} */ export var submitForm = createServerReference("ca8c1f27717ce77d454d10312ee3543368db99dc");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var getForm = createServerReference("f849c63b1d4c74b45c5ccacb6bae9c8543a2c5b9");

